<template>
  <v-row justify="center" v-if="showAll">
    <v-col cols="12" class="s-min-height">
      <div v-if="companyPaymentShowBillDetails || companyPaymentShowInfo">
        <v-stepper
          :value="companyPaymentInfo.step.value"
          class="remove-borde-background"
        >
          <v-stepper-header v-show="false" />
          <v-stepper-items>
            <v-stepper-content class="pa-0 ma-0" :step="stepPaymentInfo">
              <k-payment-info v-if="companyPaymentShowInfo" />
            </v-stepper-content>
            <v-stepper-content class="pa-0 ma-0" :step="stepPaymentResume">
              <k-payment-summary v-if="companyPaymentShowBillDetails" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
      <k-payment-fail-creditcard v-if="companyPaymentFailCreditcard" />
      <k-payment-fail-boleto v-if="companyPaymentFailBoleto" />
      <k-payment-pending v-if="companyPaymentPedingPayment" />
    </v-col>
  </v-row>
</template>

<script>
import { paymentComputed, paymentMethods, authComputed } from '@state/helpers'

const kPaymentInfo = () => import('./formPaymentInfo/formPaymentInfo.vue')
const kPaymentPending = () => import('./PaymentPending.vue')
const kPaymentFailCreditcard = () => import('./CreditcardFail.vue')
const kPaymentFailBoleto = () => import('./BoletoFail.vue')
const kPaymentSummary = () => import('./summary/summary.vue')

export default {
  name: 'payment',
  components: {
    kPaymentInfo,
    kPaymentFailCreditcard,
    kPaymentFailBoleto,
    kPaymentPending,
    kPaymentSummary,
  },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    ...paymentComputed,
    ...authComputed,
  },
  async mounted() {
    await this.getPaymentInfoByRegistering()
    this.showAll = true
  },
  methods: {
    ...paymentMethods,
  },
  watch: {
    'currentUser.idStepRegistration': {
      immediate: true,
      handler(vNew) {
        if (vNew > 3) this.$router.push({ name: 'additionalInfoForm' })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.s-min-height {
  min-width: 80px;
  @media only screen and (max-width: 1030px) {
    min-width: auto;
  }
}
.remove-borde-background {
  background-color: transparent;
  box-shadow: none;
}
</style>
